<template>
  <div>
    <!-- Delete -->
    <span
      class="btn-cursor"
      @click="showTeamModal = true"
    >
      {{ $t('Details') }}
    </span>&nbsp;&nbsp;

    <b-modal
      id="createJobModal"
      v-model="showTeamModal"
      centered
      :title="$t('Details')"
      no-close-on-backdrop
      @close="resetForm"
    >
      <validation-observer ref="editTeamRules">
        <b-form ref="formRegister">
          <!-- Club -->
          <b-form-group
            v-if="!checkPermission(['ALLOW_ALL', 'ALLOW_OSTRAIL'])"
          >
            <b>{{ $t('corporate.club') }}: </b> <label>{{ clubInfo.club_name }} </label>
          </b-form-group>

          <!-- Name -->
          <b-form-group>
            <label for="name">{{ $t('generic.name') }} <span class="text-danger">*</span></label>
            <validation-provider
              #default="{ errors }"
              rules="required|max:254|alphaNumSpacesRegex"
              name="name"
            >
              <b-form-input
                id="name"
                v-model="team.name"
                type="text"
                :placeholder="$t('generic.enterNameTeam')"
                :disabled="!checkPermission(['EDIT_TEAMS', 'ALLOW_ALL'])"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Number members -->
          <label for="name">{{ $t('generic.numberParticipants') }}: </label>
          {{ totalMember }}
          <!-- slider -->
          <vue-slider
            v-model="value"
            :min="1"
            :max="500"
            :disabled="!checkPermission(['EDIT_TEAMS', 'ALLOW_ALL'])"
          />
          <b-row>
            <b-col>
              <b-form-group>
                <!-- <label>Numero de indetificacion de camiseta</label> -->
                <label for="name">{{ $t('generic.from') }}</label>
                <validation-provider
                  #default="{ errors }"
                  rules="between:1,500"
                  name="integer"
                >
                  <b-form-input
                    id="name"
                    v-model="team.number_participants_min"
                    type="text"
                    readonly
                    :placeholder="$t('generic.enterNumberMembers')"
                    :disabled="!checkPermission(['EDIT_TEAMS', 'ALLOW_ALL'])"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group>
                <label for="name">{{ $t('generic.to') }}</label>
                <validation-provider
                  #default="{ errors }"
                  rules="between:1,500"
                  name="integer"
                >
                  <b-form-input
                    id="name"
                    v-model="team.number_participants_max"
                    type="text"
                    readonly
                    :placeholder="$t('generic.enterNumberMembers')"
                    :disabled="!checkPermission(['EDIT_TEAMS', 'ALLOW_ALL'])"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <!-- Excluir -->
          <b-form-group>
            <label>{{ $t('generic.excludeNumbers') }}</label>
            <v-select
              v-model="team.excluded_numbers"
              label="name"
              :options="handleExclude"
              multiple
              :placeholder="$t('generic.selectNumbersExclude')"
            >
              <div slot="no-options">
                {{ $t('generic.noRecordsFound') }}
              </div>
            </v-select>
          </b-form-group>

          <!-- Country -->
          <b-form-group
            v-if="checkPermission(['ALLOW_ALL', 'ALLOW_OSTRAIL'])"
          >
            <label for="club-country-id">{{ $t('generic.country') }} </label>
            <v-select
              id="club-country-id"
              v-model="team.club.country_hash"
              label="name"
              :options="countries"
              :reduce="(country) => country.id"
              :placeholder="$t('generic.selectCountry')"
            >
              <div slot="no-options">
                {{ $t('generic.noRecordsFound') }}
              </div>
            </v-select>
          </b-form-group>

          <!-- City -->
          <b-form-group
            v-if="checkPermission(['ALLOW_ALL', 'ALLOW_OSTRAIL'])"
          >
            <label for="club-state-id">{{ $t('generic.state') }}</label>
            <v-select
              id="club-state-id"
              v-model="team.club.state_hash"
              label="name"
              :options="states"
              :reduce="city => city.id"
              :disabled="team.club.country_hash == null"
              :placeholder="$t('generic.selectState')"
            >
              <div slot="no-options">
                {{ $t('generic.noRecordsFound') }}
              </div>
            </v-select>
          </b-form-group>

          <!-- Club -->
          <b-form-group
            v-if="checkPermission(['ALLOW_ALL', 'ALLOW_OSTRAIL'])"
          >
            <label>{{ $t("corporate.club") }}</label>
            <v-select
              v-model="team.club_hash"
              :placeholder="$t('corporate.selectSportClub')"
              label="club_name"
              :options="clubs"
              :reduce="club => club.hash"
              :disabled="team.club.state_hash == null"
            >
              <div slot="no-options">
                {{ $t('generic.noRecordsFound') }}
              </div>
            </v-select>
          </b-form-group>
        </b-form>
      </validation-observer>

      <template #modal-footer>
        <div class="float-right">
          <b-overlay
            :show="isLoadingDelete"
            rounded
            opacity="0.6"
            spinner-small
            spinner-variant="secondary"
            class="d-inline-block"
          >
            <b-button
              v-if="checkPermission(['DELETE_TEAMS', 'ALLOW_ALL'])"
              variant="outline-secondary"
              pill
              @click="removeTeam"
            >
              <i class="icon-0-icons-dark-trash-can mr-25" />
              <span class="align-middle">{{ $t('buttons.delete') }}</span>
            </b-button>
          </b-overlay>

          <!-- button delete -->

          <!-- Buttons cancel -->
          <b-button
            variant="secondary"
            pill
            style="margin-left: 17px;"
            @click="resetForm"
          >
            {{ $t('buttons.cancel') }}
          </b-button>

          <b-overlay
            :show="isLoadingUpdate"
            rounded
            opacity="0.6"
            spinner-small
            spinner-variant="primary"
            class="d-inline-block"
          >

            <b-button
              v-if="checkPermission(['EDIT_TEAMS', 'ALLOW_ALL'])"
              variant="primary"
              pill
              style="margin-left: 17px;"
              :style="colorPrimaryBtn"
              @click="updateTeamData"
            >
              {{ $t("buttons.save") }}
            </b-button>
          </b-overlay>

        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { mapActions } from 'vuex'
import { required } from '@validations'
import VueSlider from 'vue-slider-component'
// import Ripple from 'vue-ripple-directive'
import 'vue-slider-component/theme/antd.css'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
    VueSlider,
  },
  props: {
    clubs: {
      type: Array,
      default: null,
    },
    team: {
      type: Object,
      default: null,
    },
    states: {
      type: Array,
      default: null,
    },
    countries: {
      type: Array,
      default: null,
    },
    clubInfo: {
      type: Object,
      required: true,
      default: null,
    },
    checkPermission: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      showTeamModal: false,
      isLoadingDelete: false,
      isLoadingUpdate: false,
      required,
      min: 1,
      max: 50,
      tempQuery: {},
      paramsObj: {
        page: 1,
        perPage: 15,
      },
      filtersObj: {
        country_hash: null,
        state_hash: null,
        club_hash: null,
      },
    }
  },
  computed: {
    value: {
      get() {
        return [this.team.number_participants_min, this.team.number_participants_max]
      },
      set([min, max]) {
        this.team.number_participants_min = min
        this.team.number_participants_max = max
      },
    },
    totalMember() {
      const total = this.team.number_participants_max - this.team.number_participants_min
      if (total !== 0) {
        return total
      }
      return 50
    },
    handleExclude() {
      let index = this.team.number_participants_min
      const max = this.team.number_participants_max
      const element = []
      for (index; index < max; index += 1) {
        element.push(index)
      }
      return element
    },
  },
  watch: {
    'team.club.country_hash': function teamClubCountryId(pCountry) {
      if (pCountry !== null) {
        this.filtersObj.country_hash = pCountry
        this.handleFiltersClub(this.filtersObj)
      }
      this.team.club.state_hash = null
      this.team.club_hash = null
    },
    'team.club.state_hash': function teamClubStateId(pState) {
      if (pState !== null) {
        this.filtersObj.state_hash = pState
        this.handleFiltersClub(this.filtersObj)
      }
      this.team.club_hash = null
    },
  },
  mounted() {
    this.filtersObj.country_hash = this.team.club.country_hash
    this.handleFiltersClub(this.filtersObj)
  },
  methods: {
    ...mapActions({
      fetchStateByCountryId: 'fetchStateByCountryId',
      updateTeam: 'teamStore/update',
      destroyTeam: 'teamStore/destroy',
      colorPrimaryBtn: 'colorPrimaryBtn',
      fetchClubs: 'fetchClubs',
    }),
    async updateTeamData() {
      this.isLoadingUpdate = true
      if (this.team.club_hash == null) {
        this.team.club_hash = this.clubInfo.hash
      }
      await this.$refs.editTeamRules.validate().then(async isValid => {
        if (!isValid) {
          return
        }
        this.team.number_participants = this.totalMember
        await this.updateTeam(this.team).then(response => {
          this.responseSuccessUpdate(response.data.message)
          this.$emit('successfully')
          this.resetForm()
        }).catch(error => {
          this.responseCatch(error)
        }).finally(() => {
          this.isLoadingUpdate = false
        })
      })
    },
    async handleFiltersClub() {
      this.tempQuery.filtersObj = this.filtersObj
      await this.fetchStateByCountryId(this.filtersObj.country_hash)
      await this.fetchClubs(this.tempQuery).then(() => {
      }).catch(error => {
        this.responseCatch(error)
      })
    },
    async removeTeam() {
      this.isLoadingDelete = true
      await this.sampleDeleteQuestion()
        .then(async result => {
          if (!result.isConfirmed) {
            return
          }
          await this.destroyTeam(this.team.hash).then(response => {
            this.responseSuccessElimination(response.data.message)
            this.resetForm()
          })
        }).catch(error => {
          this.responseCatch(error)
        }).finally(() => {
          this.isLoadingDelete = false
        })
    },
    resetForm() {
      this.$emit('successfully')
      this.showTeamModal = false
    },
  },
}
</script>
<style scoped lang="scss">
.btn-cursor {
  cursor: pointer;
}
</style>
